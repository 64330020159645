<template>
  <!-- -->
  <div class="condition" :id="id">
    <slot></slot>
    <slot name="moreCondition" v-if="showMoreInfo"></slot>
    <el-button el-type="primary" v-if="hasMoreInfo" @click="toShowMoreInfo">{{showMoreInfo? "收起": "更多"}}</el-button>
    <el-button v-if="showSearch" type="primary" icon="el-icon-search" @click="search">{{searchName}}</el-button>
    <span class="option-span">
      <slot name="option"></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    showSearch: {default: true},
    searchName: {default: "查询"},
  },
  emits: ["search"],
  name: "",
  components: {
  },
  data() {
    return {
      id: "",
      // 是否显示更多查询条件
      showMoreInfo: false,
      // 是否设置了更多的查询条件
      hasMoreInfo: false,
      timeout: "",
    };
  },
  destroyed() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  created() {
    this.id = "search_condition" + (Math.random()*9999).toFixed(0);
    this.hasMoreInfo = !!this.$slots.moreCondition;
  },
  mounted() {
    // 获取当前组件的高度
    // 由于微服务框架本身的问题，mounted无法获取到dom对象
    this.$nextTick().then(() => this.resetParentHeight());
  },
  methods: {
    search() {
      this.$emit('search');
    },
    resetParentHeight() {
      if (this.$parent.addConditionHeight) {
        let docHeight = document.querySelector(`#${this.id}`).clientHeight;
        let res = this.$parent.addConditionHeight(docHeight);
        if (!res) {
          // 设置失败，等会再试
          this.timeout = setTimeout(() => this.resetParentHeight(), 300);
        }
      }
    },
    toShowMoreInfo() {
      this.showMoreInfo = !this.showMoreInfo;
      // 渲染完成后再更新
      this.$nextTick().then(() => {
        this.resetParentHeight();
      })
    }
  }
};
</script>

<style lang="less" scoped>
.condition{
  overflow: hidden;
  //padding-bottom: 20px;
  .more-info{
    color: #409eff;
    cursor: pointer;
  }
  .option-span{
    display: inline-block;
    float: right;
    padding-top: 10px;
    :deep(.el-button:last-child){
      margin-right: 0;
    }
  }
  :deep(>.el-autocomplete){
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  :deep(.el-select){
    width: 23%;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  :deep(.date-range){
    width: 23%;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  :deep(.el-button){
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    vertical-align: bottom;
  }

  :deep(.el-input:nth-last-child(2)){
    margin-right: 0;
  }
  :deep(.el-button:nth-last-child(2)){
    margin-right: 0;
  }
  :deep(.el-select:nth-last-child(2)){
    margin-right: 0;
  }
  :deep(.date-range:nth-last-child(2)){
    margin-right: 0;
  }




  :deep(.option-span .page-button:nth-last-of-type(2)){
    margin-right: 10px;
  }
}
</style>