// 引入axios
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import router from '../../router'

// 导入进度条
import NProgress from 'nprogress'

// 返回其他状态码
axios.defaults.validateStatus = function(status) {
    return status >= 200 && status <= 500
}
let cancel = null;
// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 在请求到达前展示进度条
        NProgress.start()
        if (!config.headers) {
            config.headers = {}
        }
        const token = window.localStorage.getItem('token')
        if (token) {
            config.headers.token = token
        }
      config.cancelToken = new axios.CancelToken(function (c) {
        cancel = c;
      });
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 响应完成隐藏进度条
        NProgress.done()
        const status = response.data.code || response.status
        if (status == 3000) {
            MessageBox.alert('退出登录', '登录超时，请重新登录', {
                    confirmButtonText: '确认'
                }).then(() => {
                    // 清空token
                    localStorage.removeItem('token')
                    localStorage.clear();
                    sessionStorage.clear();
                    router.replace('/')
                    location.reload()
                })
                .catch(action => {
                    // 清空token
                    localStorage.removeItem('token')
                        // location.reload();
                    router.replace('/')
                })
        } else if (status == 500) {
            Message.error(response.data.message || "接口错误！")
        } else if (status == 404) {
            Message.error(response.data.message || "404")
        } else if(status !== "2000" && response.request.responseURL.indexOf("export") < 0 && response.request.responseURL.indexOf("downLoad") < 0 && response.request.responseURL.indexOf("download") < 0){
          // 抛出异常：当状态错误，且不是导入文件，不是下载文件
          cancel && cancel();
          if (response.data.message) {
            Message.error(response.data.message)
          }
        }
        return response
    },
    error => {
      cancel && cancel()
        return Promise.reject(error)
    }
)

let url = "https://server.jinzhimc.cn";
if (location.hostname === 'scrm.jinzhimc.cn') {
  url = "https://server.jinzhimc.cn";
} else if (location.hostname === "localhost") {
  url = 'http://localhost:8101';
  // url = "https://server.jinzhimc.cn";
  // url = "https://server.xcwsmc.com";
} else {
  url = "https://server.xcwsmc.com";
}
console.log(location.hostname, url, 456);
// 配置请求根路径
axios.defaults.baseURL = url
    // axios.defaults.baseURL = 'https://server.jinzhimc.cn'

export default axios
