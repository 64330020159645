import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './assets/css/global.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import moment from 'moment'
Vue.prototype.moment = moment

// 导入进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import axios from './assets/js/api'

import url from './assets/js/url'
import Blob from './vendor/Blob'
import Export2Excel from './vendor/Export2Excel.js'

// 请求
Vue.prototype.apiURL = axios.defaults.baseURL

// 返回
// Vue.prototype.baseURL = 'http://47.109.49.94:8180/'
Vue.prototype.baseURL = axios.defaults.baseURL + '/'

// 图片限制
Vue.prototype.astrictImage = function(size) {
  const isLt5M = size / 1024 / 1024 < 5;
  if (!isLt5M) { this.$message.error("上传图片大小不能超过 5M!"); }
  return isLt5M
}

// 注册全局组件
import MyListPage from "@/components/layout/ListPage";
import MyTableList from "@/components/layout/TableList";
import MyFormPage from "@/components/layout/FormPage";
import MySearchCondition from "@/components/layout/SearchCondition";
Vue.component({
  MyListPage,
  MyTableList,
  MyFormPage,
  MySearchCondition
});

// 公共
import commonFn from './assets/js/commonFn'
Vue.prototype.commonFn = commonFn;

Vue.use(ElementUI)
Vue.prototype.$http = axios
Vue.prototype.URL = url

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.deepClone = function (obj) {
  let _tmp = JSON.stringify(obj);//将对象转换为json字符串形式
  return JSON.parse(_tmp);//将转换而来的字符串转换为原生js对象
};

Date.prototype.format =  function (fmt) {  // author: meizz
  let o = {
    "M+":  this.getMonth() + 1,  // 月份
    "d+":  this.getDate(),  // 日
    "h+":  this.getHours(),  // 小时
    "m+":  this.getMinutes(),  // 分
    "s+":  this.getSeconds(),  // 秒
    "q+": Math.floor(( this.getMonth() + 3) / 3),  // 季度
    "S":  this.getMilliseconds()  // 毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, ( this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for ( let k  in o)
    if ( new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
Array.prototype.remove = function(index) {
  return this.slice(0, index).concat(this.slice(index + 1, this.length));
}
