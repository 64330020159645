let systemName;
let serviceName;
let questionDefaultPicture; // 问卷调查的默认图片
let homeMainBackground; // 首页，显示在main中的背景图片
let iconHref; // 标题图标，显示在浏览器上的图标
let posterData = {};
if (window.location.host === "scrm.jinzhimc.cn") {
  systemName = "金致HOME数字化赋能系统";
  serviceName = "jinzhiHome";
  questionDefaultPicture = "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/common/question_default.jpg";
  homeMainBackground = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/home_jinzhi.png";
  iconHref = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/favicon.ico";
  posterData = {
    companyLogo: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/logo-jz.png",
    storeQrCode: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/mini_qr_code.jpg",
    storeName: "金致尚品旗舰店-成都总店",//门店名称
    storePhone: "028-12345678", // 门店电话
    storeAddress: "金致尚品·天府运营中心", // 门店地址
    employeeQrCode: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/mini_qr_code.jpg", //个人二维码
    employeeName: "金致尚品-业务人员", // 个人名称
    employeePhone: "133 xxx xxxx", // 个人电话
  }
} else if (window.location.host === "yun.xcwsmc.com") {
  systemName = "欣专家 云系统";
  serviceName = "xinchengwangsi";
  questionDefaultPicture = "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/common/question_default.jpg";
  homeMainBackground = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/home_xcws.jpg";
  iconHref = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/common/log_home.jpg";
  posterData = {
    companyLogo: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/logo-jz.png",
    storeQrCode: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/mini_qr_code.jpg",
    storeName: "金致尚品旗舰店-成都总店",//门店名称
    storePhone: "028-12345678", // 门店电话
    storeAddress: "金致尚品·天府运营中心", // 门店地址
    employeeQrCode: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/mini_qr_code.jpg", //个人二维码
    employeeName: "金致尚品-业务人员", // 个人名称
    employeePhone: "133 xxx xxxx", // 个人电话
  }
} else {
  systemName = "测试系统";
  serviceName = "xinchengwangsi";
  questionDefaultPicture = "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/common/question_default.jpg";
  homeMainBackground = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/home_xcws.jpg";
  iconHref = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/common/log_home.jpg";
  posterData = {
    companyLogo: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/logo-jz.png",
    storeQrCode: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/mini_qr_code.jpg",
    storeName: "金致尚品旗舰店-成都总店",//门店名称
    storePhone: "028-12345678", // 门店电话
    storeAddress: "金致尚品·天府运营中心", // 门店地址
    employeeQrCode: "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/poster/template/mini_qr_code.jpg", //个人二维码
    employeeName: "金致尚品-业务人员", // 个人名称
    employeePhone: "133 xxx xxxx", // 个人电话
  }
}
export default {
  systemName: systemName,
  serviceName: serviceName,
  filePath: "https://file.jinzhimc.cn/",
  pictureSetting: {
    // 问卷调查的默认图片
    questionDefaultPicture,
    // 首页，显示在main中的背景图片
    homeMainBackground,
    // 标题图标，显示在浏览器上的图标
    iconHref,
  },
  posterData: posterData, // 海报相关的数据
}
