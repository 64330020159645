<template>
  <!-- -->
  <div class="form-page">
    <div>
      <el-pagination
          v-if="showPage"
          v-model:current-page="currentPage"
          v-model:page-size="currentSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="prev, pager, next, jumper, total, sizes"
          background
          :total="totalCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          lang="zh-cn"
      />
      <slot name="left" v-else></slot>
    </div>
    <div><slot></slot></div>
  </div>
</template>

<script>

export default {
  emits: ["onLoad", "update:pageNumber", "update:pageSize"],
  props: {
    pageNumber: {default: 1},
    pageSize: {default: 20},
    totalCount: {default: 0},
    // 是否加载完成后自动查询数据
    defaultLoad: {default: true},
    showPage: {default: true}
  },
  watch: {
    pageNumber: {
      handler() {
        this.currentPage = this.pageNumber
      }
    },
    pageSize: {
      handler() {
        this.currentSize = this.pageSize;
      }
    }
  },
  name: "",
  components: {
  },
  data() {
    return {
      currentPage: 1,
      currentSize: 20,
    };
  },
  mounted() {
    this.$nextTick().then(() => this.resetParentHeight());
  },
  created() {
    this.currentSize = this.pageSize;
    this.currentPage = this.pageNumber;
    // 自动加载数据
    if (this.defaultLoad) {
      this.$emit("update:pageNumber", this.pageNumber);
      this.$emit("update:pageSize", this.pageSize);
      this.$emit("onLoad");
    }
  },
  methods: {
    resetParentHeight() {
      if (this.$parent.addConditionHeight) {
        let docHeight = 65;
        let res = this.$parent.addConditionHeight(docHeight);
        if (!res) {
          // 设置失败，等会再试
          this.timeout = setTimeout(() => this.resetParentHeight(), 300);
        }
      }
    },
    handleSizeChange(size) {
      this.$emit("update:pageSize", size);
      this.$emit("onLoad");
    },
    handleCurrentChange(page) {
      this.$emit("update:pageNumber", page);
      this.$emit("onLoad");
    }

  }
};
</script>

<style lang="less" scoped>
.form-page{
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f1f1f1;
}
</style>